import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { setLoginDetails } from "../../../action/actions";
import logo from '../../../images/logo-color.png'
import logo_light from '../../../images/logo-white.png'
import {formatDate, formatDateAndTime, projectLogo, projectLogoWhite, serverLink} from "../../url";
import { showAlert } from "../sweetalert/sweetalert";
import male_avater from '../../img/male_avater.jpg';


const Header = (props) => {
    const navigate = useNavigate();
    const user = props.loginData[0];
    const avatar = user?.gender === "Male" ? `${male_avater}` : `${male_avater}`;
    const username = user?.firstName+" "+user?.middleName+" "+user?.surname;
    const onLogout = () => {
        props.setOnLoginDetails([]);
        navigate("/login")
    }

    const handleClick = (event) => {
        event.preventDefault();
    };

    const getData = async () => {}

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-header">
                        <a className="nav-toggler waves-effect waves-light d-block d-md-none" id="toggle-main-nav" onClick={handleClick} href="#" >
                            <i className="ti-menu ti-close"></i>
                        </a>
                        <div className="navbar-brand">
                            <Link to="/" className="">
                                <b className="logo-icon">
                                    <img src={projectLogoWhite} width={163} height={45} alt="homepage" className="dark-logo" />
                                    {/*<img src={logo} width={163} height={45} alt="homepage" className="light-logo" />*/}
                                </b>
                                <span className="logo-text">
                                    <img src={projectLogoWhite} width={163} height={45} alt="homepage" className="dark-logo" />
                                    <img src={projectLogoWhite} width={163} height={45} className="light-logo" alt="homepage" />
                            </span>
                            </Link>
                            <a className="sidebartoggler d-none d-md-block"  onClick={handleClick} href="#"  data-sidebartype="mini-sidebar">
                                <i className="mdi mdi-toggle-switch mdi-toggle-switch-off font-20"></i>
                            </a>
                        </div>
                        <a className="topbartoggler d-block d-md-none waves-effect waves-light"  onClick={handleClick} href="#"  data-toggle="collapse" data-target="#navbarSupportedContent"
                           aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="ti-more"></i>
                        </a>
                    </div>
                    <div className="navbar-collapse collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav float-left mr-auto">
                            {/*<li className="nav-item search-box">*/}
                            {/*    <a className="nav-link waves-effect waves-dark"  onClick={handleClick} href="#" >*/}
                            {/*        <div className="d-flex align-items-center">*/}
                            {/*            <i className="mdi mdi-magnify font-20 mr-1"></i>*/}
                            {/*            <div className="ml-1 d-none d-sm-block">*/}
                            {/*                <span>Search</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </a>*/}
                            {/*    <form className="app-search position-absolute">*/}
                            {/*        <input type="text" className="form-control" placeholder="Search &amp; enter"/>*/}
                            {/*        <a className="srh-btn">*/}
                            {/*            <i className="ti-close"></i>*/}
                            {/*        </a>*/}
                            {/*    </form>*/}
                            {/*</li>*/}
                        </ul>
                        <ul className="navbar-nav float-right">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle waves-effect waves-dark pro-pic" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src={avatar} alt="user" className="rounded-circle" width="40"/>
                                    <span className="m-l-5 font-medium d-none d-sm-inline-block">{username} <i className="mdi mdi-chevron-down"></i></span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                                <span className="with-arrow">
                                    <span className="bg-primary"></span>
                                </span>
                                    <div className="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                                        <div className="">
                                            <img src={avatar} alt="user" className="rounded-circle" width="60"/>
                                        </div>
                                        <div className="m-l-10">
                                            <h4 className="m-b-0">{username}</h4>
                                            <p className=" m-b-0">{user?.emailAddress}</p>
                                        </div>
                                    </div>
                                    <div className="profile-dis scrollable">
                                        <a className="dropdown-item"  onClick={handleClick} href="#" >
                                            <i className="ti-user m-r-5 m-l-5"></i> My Profile</a>
                                        <a className="dropdown-item"  onClick={handleClick} href="#" >
                                            <i className="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item"  onClick={handleClick} href="#" >
                                            <i className="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                                        <div className="dropdown-divider"></div>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
