import PageTitle from "../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {currencyConverter, formatDateAndTime, serverLink} from "../url";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import DataTable from "../common/data-table/data-table";
import Loader from "../common/loader";
import {showAlert} from "../common/sweetalert/sweetalert";
import FundWallet from "./fund-wallet";
import {TbCurrencyNaira} from "react-icons/tb";

function PatientPaymentList({loginData}) {
    const token = loginData[0]?.token;
    const patientSerial = loginData[0]?.patientSerial;
    const [patientDetails, setPatientDetails] = useState(loginData[0])
    const [IsLoading, setIsLoading] = useState(true)
    const [paymentList, setPaymentList] = useState([])
    const header = ["S/N", "Payment Purpose", "Payment Method", "Amount Due",  "Amount Paid", "Payment Type", "Status", "Payment Date"];
    const [rebuildPayment, setRebuildPayment] = useState(null);
    const [patientWalletAmount, setPatientWalletAmount] = useState(0)


    useEffect( () => {
        getData();
    }, [rebuildPayment]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/wallet/${patientSerial}`)
            .then((result) => {
                if (result.data.length > 0){
                    setPatientWalletAmount(result.data[0].walletAmount);
                }else {
                    setPatientWalletAmount(0)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

        await axios.get(`${serverLink}patient-portal/payment/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPaymentList(result.data);
                }else {
                    setPaymentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return paymentList.length > 0 &&  paymentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.itemName}</td>
                        <td>{item.paymentMethod}</td>
                        <td>{currencyConverter(item.amountDue)}</td>
                        <td>{currencyConverter(item.amountPaid)}</td>
                        <td className={item.paymentType === 'Debit' ? 'text-danger' : 'text-success'}>{item.paymentType}</td>
                        <td>{item.paymentStatus}</td>
                        <td>{formatDateAndTime(item.paymentDate, 'date')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Payment"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">My Payment(s)</h4>
                                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                    <div className="" style={{color: '#1a8393'}}>
                                        <h3><b>Current Wallet Balance: {currencyConverter(patientWalletAmount)}</b></h3>
                                    </div>
                                    <div className="ml-auto">
                                        <div className="btn-group">
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                data-toggle="modal"
                                                data-target="#fund-modal"
                                            >
                                                <TbCurrencyNaira size={25}/> TopUp Wallet
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <DataTable tableID="patient-payment" header={header} body={showTable()}
                                               title="My Payment(s)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FundWallet
                id="fund-modal"
                title="Fund Wallet"
                close="closeFund"
                patientSerial={patientDetails.patientSerial}
                patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                walletAmount={patientDetails.walletAmount}
                EmailAddress={patientDetails.emailAddress}
                setRebuildPayment={setRebuildPayment}
            />
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientPaymentList);
