import PageTitle from "../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {decryptData, encryptData, formatDateAndTime, serverLink} from "../url";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import DataTable from "../common/data-table/data-table";
import Loader from "../common/loader";
import {Link} from "react-router-dom";
import {setLoginDetails} from "../../action/actions";

function ChangePassword({loginData, setOnLoginDetails}) {
    const token = loginData[0]?.token;
    const patientSerial = loginData[0]?.patientSerial;
    const pass = decryptData(loginData[0]?.password);
    const [IsLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        Username: patientSerial,
        OldPassword: "",
        Password: "",
        CPassword: "",
    })

    const onChangePassword = (e) => {
        e.preventDefault();
        if (formData.OldPassword.toString().trim() === ''){
            toast.error('Please enter old password');
            return false;
        }
        if (formData.Password.toString().trim() === ''){
            toast.error('Please enter new password');
            return false;
        }
        if (formData.CPassword.toString().trim() === ''){
            toast.error('Please enter confirm password');
            return false;
        }
        if (formData.OldPassword !== pass){
            toast.error('Incorrect old password');
            return false;
        }

        if (formData.Password.toString().length < 8){
            toast.error('Password must be 8 or more characters');
            return false;
        }

        if (formData.Password !== formData.CPassword) {
            toast.error('Passwords do not match. Please make sure your passwords match.');
            return false;
        }

        setLoading(true);
        toast.info("Please wait...")

        const data = {
            Username: formData.Username,
            Password: encryptData(formData.Password)
        }
        axios.patch(`${serverLink}login/patient/change-password`, data).then((res) => {
            if (res.data.message === "success") {
                toast.success("Password Updated Successfully");
                setOnLoginDetails([{...loginData[0], password: data.Password}])
                setFormData({
                    ...formData,
                    OldPassword: "",
                    Password: "",
                    CPassword: "",
                })
            } else {
                setLoading(false);
                toast.error('Something went wrong, please try again.')
            }
        }).catch((e)=>{
            toast.error('Network Error!, please try again.')
        })
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }


    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Change Password"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                    <div className="ml-auto">

                                    </div>
                                </div>
                                <form className="form-horizontal m-t-20 col-md-6 offset-sm-3" id="loginform"
                                      onSubmit={onChangePassword}>
                                    <div className="form-group alert alert-info mb-3">
                                        Strong password required. 8-256 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers and symbols.
                                    </div>
                                        <div className="form-group mb-3">
                                            <label>Old Password</label>
                                            <input type="password" className="form-control form-control-lg"
                                                   id="OldPassword"
                                                   onChange={onEdit} value={formData.OldPassword} required
                                                   placeholder="Old Password"/>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label>New Password</label>
                                            <input type="password" className="form-control form-control-lg"
                                                   id="Password"
                                                   onChange={onEdit} value={formData.Password} required
                                                   placeholder="New Password"/>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label>Confirm Password</label>
                                            <input type="password" className="form-control form-control-lg"
                                                   id="CPassword"
                                                   onChange={onEdit} value={formData.CPassword} required
                                                   placeholder="Confirm Password"/>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <div className="custom-control custom-checkbox">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group text-center">
                                            <div className="col-xs-12 p-b-20">
                                                <button className="btn btn-block btn-lg btn-info" type="submit">UPDATE
                                                    PASSWORD
                                                </button>
                                            </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        }
    };
};



const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
