import React from "react";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../common/header/header";
import SideBar from "../common/sidebar/sidebar";
import DashBoard from "../dashboard/dashboard";
import Footer from "../common/footer/footer";
import PatientAppointmentList from "../appointment/appointment";
import PatientPaymentList from "../finance/payments";
import ChangePassword from "../change-password/change-password";
import PatientProfile from "../profile/profile";

const PageRoutes = (props) => {
  const [toggle, setToggle] = useState("");

  const toggleSidebar = () => {
    const tg = toggle === "" ? "toggle-sidebar" : "";
    setToggle(tg);
  };

  return (
    <div className={toggle}>
      <Header toggleSidebar={toggleSidebar} />
      <SideBar />
      <div className="page-wrapper">
        <Routes>
          <Route path={"/"} element={<DashBoard />} />
          <Route path={"/dashboard"} element={<DashBoard />} />

          {/*/!* ROUTE *!/*/}
          <Route path="/appointment" element={<PatientAppointmentList />} />
          <Route path="/payment" element={<PatientPaymentList />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/profile" element={<PatientProfile />} />
          {/*<Route path="/website/gallery" element={<div className="text-center alert alert-info"><h1>Page Under Construction</h1></div>} />*/}



          <Route path="*" element={<DashBoard />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};
export default PageRoutes;
