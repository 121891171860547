import React from "react";
import { useState } from "react";
import PageTitle from "../common/pagetitle/pagetitle";
import axios from 'axios'
import {calculateAge, currencyConverter, formatDate, formatDateAndTime, imageExists, serverLink} from "../url";
import { showAlert } from "../common/sweetalert/sweetalert";
import { useEffect } from "react";
import { connect } from "react-redux";
import PageLoader from "../common/loader";
import {Info} from "@material-ui/icons";
import {Link} from "react-router-dom";


const DashBoard = (props) => {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const patientSerial = props.loginData[0]?.patientSerial;
    const [patientDetails, setPatientDetails] = useState(props.loginData[0])
    const imagePath = `${serverLink}public/uploads/patient_uploads/${patientDetails.passport}`;
    const placeholderImage = require('../img/male_avater.jpg');
    const [paymentList, setPaymentList] = useState([])
    const [appointmentList, setAppointmentList] = useState([])
    const [completedAppointment, setCompletedAppointment] = useState(0)
    const [scheduledAppointment, setScheduledAppointment] = useState(0)

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient-portal/payment/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPaymentList(result.data.slice(0,3));
                }else {
                    setPaymentList([])
                }
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

        await axios.get(`${serverLink}patient-portal/appointment/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setAppointmentList(result.data);
                    setScheduledAppointment(result.data.filter(e=>e.appointmentStatus !== 'Completed').length);
                    setCompletedAppointment(result.data.filter(e=>e.appointmentStatus === 'Completed').length);
                }else {
                    setAppointmentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    useEffect(() => { getData(); }, [])

    return (IsLoading ? <PageLoader/> :
            <>
                <PageTitle title={["Home", "Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="status m-t-30 d-flex justify-content-center align-items-center text-center">
                                        <img
                                            src={imageExists(imagePath) ? imagePath : placeholderImage}
                                            alt="passport"
                                            className="rounded-circle border"
                                            width="150"
                                            height="150"
                                        />
                                    </div>
                                    <div className="text-center">
                                        <h4 className="card-title m-t-10">{patientDetails.firstName} {patientDetails.middleName} {patientDetails.surname}</h4>
                                        <h5 className="card-subtitle font-weight-bold text-dark">{patientDetails.patientSerial}</h5>
                                        <h4 className="card-subtitle font-weight-bold text-danger">WALLET
                                            AMOUNT: {currencyConverter(patientDetails.walletAmount)}</h4>
                                        {/*<h5 className="card-subtitle"><span className="badge badge-success">{patientDetails.status}</span></h5>*/}
                                    </div>
                                    <hr/>
                                    <div className="text-center"><h4><b>Appointments</b></h4></div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-6 border-right">
                                            <i className="fa fa-circle text-success float-right"></i>
                                            <h4 className="mb-0 font-medium">{scheduledAppointment}</h4>
                                            <span>Upcoming</span>
                                        </div>
                                        <div className="col-6 p-l-20">
                                            <i className="fa fa-circle text-secondary float-right"></i>
                                            <h4 className="mb-0 font-medium">{completedAppointment}</h4>
                                            <span>Completed</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h4 className="card-title">Other Info</h4>
                                        </div>
                                        <div className="ml-auto">
                                            <Link
                                                to="/profile"
                                                className="btn btn-info"
                                            >
                                                <i className="fa fa-edit"></i> Edit Profile
                                            </Link>
                                        </div>
                                    </div>
                                    <div><hr/></div>
                                    <div className="chart1 m-t-10" style={{position: 'relative', height:'315px'}}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <small className="text-muted">Gender</small>
                                                <h6>{patientDetails.gender ?? "N/A"}</h6>
                                                <small className="text-muted">Date of Birth </small>
                                                <h6>{formatDateAndTime(patientDetails.dateOfBirth, 'date')} -
                                                    ({calculateAge(patientDetails.dateOfBirth)} Years Old)</h6>
                                                <small className="text-muted">Blood Group</small>
                                                <h6>{patientDetails.bloodGroup ?? "N/A"}</h6>
                                                <small className="text-muted">Marital Status</small>
                                                <h6>{patientDetails.maritalStatus ?? "N/A"}</h6>
                                                <small className="text-muted">Email address </small>
                                                <h6>{patientDetails.emailAddress ?? "N/A"}</h6>
                                                <small className="text-muted p-t-30 db">Phone</small>
                                                <h6>{patientDetails.phoneNumber} | {patientDetails.altPhoneNumber ?? "N/A"}</h6>
                                                <small className="text-muted p-t-30 db">Address</small>
                                                <h6>{patientDetails.residentialAddress ?? "N/A"}</h6>
                                            </div>
                                            <div className="col-md-6">
                                                <small className="text-muted">Nationality</small>
                                                <h6>{patientDetails.nationality ?? "N/A"}</h6>
                                                <small className="text-muted">State </small>
                                                <h6>{patientDetails.state ?? "N/A"}</h6>
                                                <small className="text-muted">LGA</small>
                                                <h6>{patientDetails.lga ?? "N/A"}</h6>
                                                <small className="text-muted">Ward</small>
                                                <h6>{patientDetails.ward ?? "N/A"}</h6>
                                                <small className="text-muted">Occupation </small>
                                                <h6>{patientDetails.occupation ?? "N/A"}</h6>
                                                <small className="text-muted p-t-30 db">Office Address</small>
                                                <h6>{patientDetails.officeAddress ?? "N/A"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Payments</h4><hr/>
                                </div>
                                {
                                    paymentList.length > 0 ?
                                        <>
                                            <table className="table m-b-0">
                                                <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Payment Purpose</th>
                                                    <th scope="col">Method</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Payment Type</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    paymentList.length > 0 && paymentList.map((item, index)=>{
                                                        return(
                                                            <tr key={index}>
                                                                <th scope="row">{index+1}</th>
                                                                <td>{item.itemName}</td>
                                                                <td>{item.paymentMethod}</td>
                                                                <td>{currencyConverter(item.amountPaid)}</td>
                                                                <td className={item.paymentType === 'Debit' ? 'text-danger' : 'text-success'}>{item.paymentType}</td>
                                                            </tr>
                                                        )
                                                    })

                                                }
                                                </tbody>
                                            </table>
                                            <div className="mt-4 mb-3 text-center">
                                                <Link to='/payment' className="text-decoration-underline"><u>View More</u></Link>
                                            </div>
                                        </>
                                        :
                                        (
                                            <div className='text-center p-5'>
                                                <h2 className='p-1'>
                                                    <Info size='19' className='me-25'/> No Record Found
                                                </h2>
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};


export default connect(mapStateToProps, null)(DashBoard);

