import PageTitle from "../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {formatDate, serverLink} from "../url";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Loader from "../common/loader";
import StateData from "../resources/state_and_lga.json";
import CountryData from "../resources/country.json";
import {PatientInfoCardLarge} from "../patient/utils/patient-info-card-large";
import {showAlert} from "../common/sweetalert/sweetalert";
import EditProfileForm from "./edit-profile-form";
import {setLoginDetails} from "../../action/actions";

function PatientProfile({loginData, setOnLoginDetails}) {
    const token = loginData[0]?.token;
    const patientSerial = loginData[0]?.patientSerial;
    const [patientDetails, setPatientDetails] = useState(loginData[0])
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [patientList, setPatientList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLgaList] = useState([]);
    const [ImagePath, setImagePath] = useState('')
    const [ImageDisplay, setImageDisplay] = useState('')



    const InitValue = {
        patientID: "",
        title: "",
        firstName: "",
        middleName: "",
        surname: "",
        passport: "",
        ImagePath: "",
        emailAddress: "",
        phoneNumber: "",
        gender: "",
        dateOfBirth: "",
        bloodGroup: "",
        maritalStatus: "",
        nextOfKinName: "",
        nextOfKinPhone: "",
        nextOfKinRelationship: "",
        residentialAddress: "",
        nationality: "",
        state: "",
        lga: "",
        patientSerial: "",
        occupation: "",
        altPhoneNumber: "",
        password: "",
        ward: "",
        zipCode: "",
        officeAddress: "",
        religion: "",
        submittedBy: `${loginData[0]?.patientSerial}`,
    };

    const [formData, setFormData] = useState(InitValue);
    const resetItem = () => {
        setFormData(InitValue);
    }

    useEffect( () => {
        getData();

        StateData.map(state => {
            if (state.state !== 'Non-Nigerian')
                setStateList(stateList => [...stateList, state.state])
            //stateList.push(state.state)
        });

        StateData.map(state => {
            if(state.state === patientDetails.state) {
                state.lgas.map(lga => {
                    setLgaList(lgaList => [...lgaList, lga])
                })
            }
        })
    }, []);

    const getData = async () => {
        setIsLoading(true)
        await axios
            .get(`${serverLink}patient-portal/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setOnLoginDetails([{...loginData[0], ...result.data[0]}])
                    setPatientList(result.data);
                } else {
                    setPatientList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        if (id === 'nationality') {
            setStateList([])
            setLgaList([])
            setFormData({
                ...formData,
                state: "",
            })
            if (value === 'Nigeria') {
                stateData();
            } else {
                StateData.map(state => {
                    if (state.state === 'Non-Nigerian')
                        setStateList(stateList => [...stateList, state.state])
                    //stateList.push(state.state)
                });
            }
        }

        if(e.target.name === 'state') {
            setLgaList([])
            StateData.map(state => {
                if(state.state === e.target.value) {
                    state.lgas.map(lga => {
                        setLgaList(lgaList => [...lgaList, lga])
                    })
                }
            })
        }

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const onSubmit = async () => {
        if(formData.title === "") {
            showAlert("Empty Field", "Please Select the Patient Title", "error");
            return false;
        }

        if(formData.firstName === "") {
            showAlert("Empty Field", "Please Select the Patient First Name", "error");
            return false;
        }

        if(formData.surname === "") {
            showAlert("Empty Field", "Please Enter the Patient Surname", "error");
            return false;
        }

        if(formData.phoneNumber === "") {
            showAlert("Empty Field", "Please Enter the Patient Phone Number", "error");
            return false;
        }

        if(formData.gender === "") {
            showAlert("Empty Field", "Please Select the Patient Gender", "error");
            return false;
        }

        if(formData.bloodGroup === "") {
            showAlert("Empty Field", "Please Select the Patient Blood Group", "error");
            return false;
        }



        if(formData.dateOfBirth === "" ||  formData.dateOfBirth === null) {
            showAlert("Empty Field", "Please Select the Patient Date of Birth", "error");
            return false;
        }


        if(formData.maritalStatus === "") {
            showAlert("Empty Field", "Please Select the Patient Marital Status", "error");
            return false;
        }

        if(formData.nextOfKinName === "") {
            showAlert("Empty Field", "Please Enter the Patient Next of Kin Name", "error");
            return false;
        }

        if(formData.nextOfKinPhone === "") {
            showAlert("Empty Field", "Please Enter the Patient Next of Kin Phone Number", "error");
            return false;
        }

        if(formData.nextOfKinRelationship === "") {
            showAlert("Empty Field", "Please Select the Patient Next of Kin Relationship", "error");
            return false;
        }


        if(formData.nationality === "") {
            showAlert("Empty Field", "Please Select Nationality", "error");
            return false;
        }

        if(formData.state === "") {
            showAlert("Empty Field", "Please Select the Patient State", "error");
            return false;
        }

        if(formData.lga === "") {
            showAlert("Empty Field", "Please Select the Patient LGA", "error");
            return false;
        }

        if(formData.religion === "") {
            showAlert("Empty Field", "Please Select the Patient Religion", "error");
            return false;
        }


        if(formData.residentialAddress === "") {
            showAlert("Empty Field", "Please Select the Patient Residential Address", "error");
            return false;
        }

        const sendData = {
            ...formData,
            dateOfBirth: formatDate(formData.dateOfBirth),
        }

        await axios
            .patch(`${serverLink}patient-portal/update`, sendData, token)
            .then(async (result) => {
                if (result.data.message === "success") {
                    if (ImagePath !== '') {
                        const formData2 = new FormData();
                        formData2.append('photo', ImagePath);
                        formData2.append('entry_id', formData.patientID)
                        axios.patch(`${serverLink}patient/uploadPatientPhoto`, formData2)
                            .then(async (result) => {
                                toast.success("Patient Updated Successfully");
                                document.getElementById("closeProfile").click();
                                await getData();
                                setIsFormLoading(false);
                                setIsSubmittingForm(false);
                                resetItem();
                                window.location.reload()
                            })
                            .catch(err => {
                                setIsFormLoading(false);
                                showAlert(
                                    "ERROR",
                                    "Something went wrong uploading the image. Please try again!",
                                    "error"
                                );
                            });
                    }else{
                        toast.success("Patient Updated Successfully");
                        setIsFormLoading(false);
                        document.getElementById("closeProfile").click();
                        await getData();
                        setIsSubmittingForm(false);
                        resetItem();
                    }
                } else {
                    await showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                    setIsSubmittingForm(false);
                }
            })
            .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
                setIsSubmittingForm(false);
            });
    };

    const stateData = () => {
        StateData.map(state => {
            if (state.state !== 'Non-Nigerian')
                setStateList(stateList => [...stateList, state.state])
        });
    }


    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "My Profile"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex no-block align-items-center m-b-20 mt-2">
                                    <div className="ml-auto">
                                        <div className="btn-group">
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                data-toggle="modal"
                                                data-target="#responsive-modal"
                                                onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        patientID: patientDetails.patientID,
                                                        title: patientDetails.title,
                                                        firstName: patientDetails.firstName,
                                                        middleName: patientDetails.middleName,
                                                        surname: patientDetails.surname,
                                                        passport: patientDetails.passport,
                                                        emailAddress: patientDetails.emailAddress,
                                                        phoneNumber: patientDetails.phoneNumber,
                                                        gender: patientDetails.gender,
                                                        dateOfBirth: patientDetails.dateOfBirth,
                                                        bloodGroup: patientDetails.bloodGroup,
                                                        maritalStatus: patientDetails.maritalStatus,
                                                        nextOfKinName: patientDetails.nextOfKinName,
                                                        nextOfKinPhone: patientDetails.nextOfKinName,
                                                        nextOfKinRelationship: patientDetails.nextOfKinRelationship,
                                                        residentialAddress: patientDetails.residentialAddress,
                                                        nationality: patientDetails.nationality,
                                                        religion: patientDetails.religion,
                                                        occupation: patientDetails.occupation,
                                                        altPhoneNumber: patientDetails.altPhoneNumber,
                                                        state: patientDetails.state,
                                                        lga: patientDetails.lga,
                                                        patientSerial: patientDetails.patientSerial,
                                                        walletAmount: patientDetails.walletAmount,
                                                        password: patientDetails.password,
                                                        ward: patientDetails.ward,
                                                        zipCode: patientDetails.zipCode,
                                                        status: patientDetails.status,
                                                        officeAddress: patientDetails.officeAddress,
                                                    });
                                                    setImagePath('')
                                                }}
                                            >
                                                <i className="fa fa-edit"></i> Edit Profile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <PatientInfoCardLarge patientDetails={patientDetails}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditProfileForm
                title="Edit Profile"
                close="closeProfile"
                formData={formData}
                onEdit={onEdit}
                onSubmit={onSubmit}
                countryData={CountryData}
                stateList={stateList}
                lgaList={lgaList}
                IsFormLoading={IsFormLoading}
                setImagePath={setImagePath}
                setImageDisplay={setImageDisplay}
            />
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);
