import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer';
import {setDashboardDetails, setLoginDetails, setPermissionDetails} from "../../../action/actions";
import {Money} from "@material-ui/icons";
import {FaNairaSign} from "react-icons/fa6";
import {TbCurrencyNaira} from "react-icons/tb";
import {CalendarMonthOutlined, LockReset, Logout} from "@mui/icons-material";
import {AiOutlineUser} from "react-icons/ai";

const SideBar = (props) => {
    const [menuList, setMenuList] = useState([])
    const [subMenuList, setSubMenuList] = useState([])
    const [permissionList, setPermissionList] = useState(props.permissionData)
    const [activeMenu, setActiveMenu] = useState(null);

    const handleClick = (menu) => {
        setActiveMenu(menu === activeMenu ? null : menu);
    };

    const toggleClick = (menu) => {
        document.getElementById("toggle-main-nav").click();
    };

    const SESSION_IDLE_MINUTES = 10;
    const handleOnIdle = (event) => {
        console.log('last active', getLastActiveTime())
        signOut();
    }

    const {getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    const signOut = () => {
        props.setOnLoginDetails([]);
        props.setOnPermissionDetails([]);
    };

    return (
        <>
            <aside className="left-sidebar">
                <div className="scroll-sidebar">
                    <nav className="sidebar-nav">
                        <ul id="sidebarnav">
                            <li className="nav-small-cap">
                                <i className="mdi mdi-dots-horizontal"></i>
                                <span className="hide-menu">MENU</span>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                      onClick={()=>toggleClick()}
                                   to="/" aria-expanded="false">
                                    <i className="fa fa-desktop"></i>
                                    <span className="hide-menu">Dashboard</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                      onClick={()=>toggleClick()}
                                   to="/appointment" aria-expanded="false">
                                    <CalendarMonthOutlined/>
                                    <span className="hide-menu">Appointments</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                      onClick={()=>toggleClick()}
                                   to="/payment" aria-expanded="false">
                                    <TbCurrencyNaira size={30}/>
                                    <span className="hide-menu">Payments</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                      onClick={()=>toggleClick()}
                                   to="/profile" aria-expanded="false">
                                    <AiOutlineUser size={30}/>
                                    <span className="hide-menu">Profile</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                      onClick={()=>toggleClick()}
                                   to="/change-password" aria-expanded="false">
                                    <LockReset size={30}/>
                                    <span className="hide-menu">Change Password</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                   to="/" aria-expanded="false" onClick={signOut}>
                                    <Logout/>
                                    <span className="hide-menu">Log Out</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        permissionData: state.PermissionDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
